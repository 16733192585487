<template>
  <img :class="[$style.img, $style[size], responsive && $style.responsive]" />
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    size?: "l" | "m" | "s";
    responsive?: boolean;
  }>(),
  {
    size: "m",
    responsive: false,
  },
);
const { size, responsive } = toRefs(props);
</script>

<style lang="scss" module>
.img {
  object-fit: cover;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
@mixin l-size {
  border-radius: var(--radius-6xl);
  max-width: 180px;
  width: 75%;
  aspect-ratio: 1/1;
}
@mixin m-size {
  border-radius: var(--radius-5xl);
  max-width: 142px;
  width: 75%;
  aspect-ratio: 1/1;
}
@mixin s-size {
  border-radius: var(--radius-xl);
  max-width: 86px;
  width: 85%;
  aspect-ratio: 1/1;
}
.l {
  @include l-size;
}
.m {
  @include m-size;
}
.s {
  @include s-size;
}
.responsive {
  @include l-size;
  border-radius: 17.77778%;
  @media screen and (max-width: $tablet) {
    @include m-size;
    border-radius: 16.90144%;
  }
  @media screen and (max-width: $mobile) {
    @include s-size;
    border-radius: 18.60465%;
  }
}
</style>
