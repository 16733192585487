<template>
  <div :class="$style.base_slide">
    <SharedHeadingHeading1><slot name="title" /></SharedHeadingHeading1>
    <p><slot name="description" /></p>
    <slot name="picture"></slot>
    <div :class="$style.base_slide_controls">
      <slot name="controls"></slot>
    </div>
  </div>
</template>
<style module lang="scss">
.base_slide {
  position: relative;
  height: 100%;
  width: 100%;
  h1 {
    padding-top: 80px;
    margin: 0 auto;
    text-align: center;
  }
  p {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: normal;
    @media screen and (max-width: $tablet) {
      font-size: 1rem;
    }
    @media screen and (max-width: $mobile) {
      font-size: 0.875rem;
    }
  }
  &_controls {
    position: absolute;
    bottom: 80px;
    display: flex;
    gap: var(--spacing-2lg);
    z-index: 1;
    @media screen and (max-width: $tablet) and (min-width: $mobile) {
      bottom: 95px;
    }
  }

  @media screen and (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h1 {
      padding-top: 64px;
    }
  }
}
</style>
