<template>
  <div :class="$style.swiper_wrapper">
    <NuxtErrorBoundary>
      <swiper
        :speed="swiperSet.speed"
        :pagination="swiperSet.pagination"
        :navigation="swiperSet.navigation"
        :modules="swiperSet.modules"
        :autoplay="swiperSet.autoplay"
        :loop="swiperSet.loop"
        :class="$style.swiper"
      >
        <swiper-slide>
          <Slide />
        </swiper-slide>
        <swiper-slide>
          <Slide2 />
        </swiper-slide>
      </swiper>
      <div
        ref="paginationRef"
        :class="$style.pagination"
      ></div>
    </NuxtErrorBoundary>
  </div>
</template>

<script lang="ts" setup>
import type { SwiperOptions } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";
import Slide from "~/components/MainSwiper/Slide.vue";
import Slide2 from "~/components/MainSwiper/Slide2.vue";
import "swiper/css";
import "swiper/css/pagination";

const paginationRef = ref<HTMLElement | null>(null);
const style = useCssModule();
const swiperSet: SwiperOptions = reactive({
  speed: 600,
  pagination: {
    clickable: true,
    el: paginationRef,
    bulletClass: style.bullet,
    bulletActiveClass: style.bullet_active,
  },
  modules: [Autoplay, Pagination],
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  loop: true,
});
</script>

<style lang="scss" module>
.swiper_wrapper {
  position: relative;
  margin-top: -45px;
  margin-bottom: -60px;
  .swiper {
    width: 100%;
    height: 480px;
    overflow: hidden;
    @media screen and (max-width: $tablet) {
      height: 520px;
    }
    @media screen and (max-width: $tablet) {
      height: 530px;
    }
  }
  .pagination {
    width: auto !important;
    position: absolute;
    left: 50% !important;
    bottom: 60px !important;
    transform: translateX(-50%);
    background: var(--general-transparent-dark-35);
    padding: var(--spacing-xs) var(--spacing-md);
    border-radius: var(--radius-max);
    z-index: 1;
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-xs);
    .bullet {
      width: 6px;
      height: 6px;
      border-radius: var(--radius-max);
      opacity: 0.3;
      background: var(--general-transparent-light-100);
      &:first-child,
      &:last-child {
        width: 4px;
        height: 4px;
      }
      &_active {
        opacity: 1;
      }
    }
  }
}
</style>
