<template>
  <div
    :class="[$style.container, $style[size], responsive && $style.responsive]"
  >
    <div :class="$style['image-container']">
      <SharedDiscount
        v-if="discount"
        :class="$style['discount']"
        >{{ discount }}</SharedDiscount
      >
      <SharedAppCardImage
        :src="img"
        :size="size"
        :class="$style.image"
        :responsive="responsive"
      />
    </div>
    <div :class="$style['app-name']">{{ app }}</div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    img: string | null;
    app: string;
    size?: "l" | "m" | "s";
    responsive?: boolean;
    discount?: number | string;
  }>(),
  {
    size: "m",
    responsive: false,
  },
);
const { img, app, size, responsive, discount } = toRefs(props);
</script>

<style lang="scss" module>
@use "sass:math";
.container {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: var(--spacing-2xl);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-lg, 14px);
  flex: 0 0;
  background: var(--app-action-cards-default, #1c1c1c);

  &:hover {
    background: linear-gradient(
        225.11deg,
        rgba(64, 64, 64, 0.4) 0%,
        rgba(132, 132, 132, 0.4) 53.74%,
        rgba(236, 236, 236, 0.4) 98.6%
      ),
      linear-gradient(
        0deg,
        var(--app-action-cards-hover),
        var(--app-action-cards-hover)
      );
  }
  &:active {
    background: linear-gradient(
        225.11deg,
        rgba(64, 64, 64, 0.4) 0%,
        rgba(132, 132, 132, 0.4) 53.74%,
        rgba(236, 236, 236, 0.4) 98.6%
      ),
      linear-gradient(
        0deg,
        var(--app-action-cards-hover),
        var(--app-action-cards-hover)
      );
  }
  &:disabled {
    background: var(--app-action-cards-disabled, rgba(76, 76, 76, 0.12));
    .image {
      opacity: 0.5;
    }
  }
}

.discount {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
}

@mixin l-size {
  width: 328px;
  border-radius: var(--radius-8xl);
  font-size: 18px;
  letter-spacing: -0.09px;
}
@mixin m-size {
  width: 246px;
  border-radius: var(--radius-7xl);
  font-size: 18px;
  letter-spacing: -0.09px;
}
@mixin s-size {
  width: 167px;
  border-radius: var(--radius-5xl);
  font-size: 14px;
  letter-spacing: -0.07p;
}
.l {
  @include l-size;
}
.m {
  @include m-size;
}
.s {
  @include s-size;
}
.responsive {
  @include l-size;
  border-radius: 14.63415%;
  @media screen and (max-width: $tablet) {
    @include m-size;
    border-radius: 16.26016%;
  }
  @media screen and (max-width: $mobile) {
    @include s-size;
    border-radius: 14.37126%;
  }
}
.app-name {
  text-align: center;
}
.image-container {
  position: relative;
  .image {
    width: 100%;
  }
}
</style>
