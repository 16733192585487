<template>
  <BaseSlide :class="$style.slide">
    <template #title>{{ t("home.swiper.first_slide.title") }}</template>
    <template #description>{{
      t("home.swiper.first_slide.description")
    }}</template>
  </BaseSlide>
</template>

<script setup lang="ts">
import BaseSlide from "./BaseSlide.vue";

const { t } = useI18n();
</script>

<style lang="scss" module>
.slide {
  background-image: url("~/assets/slides/main/first/desktop.png"),
    linear-gradient(355deg, #7dc8fd 0%, #0c8aff 106.55%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  svg {
    width: 16px;
  }
  p {
    margin-bottom: 60px;
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (max-width: $tablet) {
    background-image: url("~/assets/slides/main/first/tablet.png"),
      linear-gradient(355deg, #7dc8fd 0%, #0c8aff 106.55%);
  }
  @media screen and (max-width: $mobile) {
    background-image: url("~/assets/slides/main/first/mobile.png"),
      linear-gradient(355deg, #7dc8fd 0%, #0c8aff 106.55%);
  }
}
</style>
