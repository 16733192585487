<template>
  <Heading3 :class="$style['main-block_title']"
    >{{ title }}
    <span
      v-if="count"
      :class="$style['main-block_title_count']"
      >{{ count }}</span
    >
  </Heading3>
</template>

<script setup lang="ts">
import Heading3 from "~/components/Shared/Heading/Heading3.vue";

const props = defineProps<{
  title: string;
  count?: number;
}>();

const { title, count } = toRefs(props);
</script>

<style module lang="scss">
.main-block_title {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  margin-left: var(--spacing-xs);

  &_count {
    color: var(--general-transparent-light-35);
  }

  @media screen and (max-width: $mobile) {
    gap: var(--spacing-2xs);
  }
}
</style>
